.slick-prev, .slick-next {
    width: 30px;
    height: 30px;
}
.slick-prev {
    left: -35px;
}
.slick-next {
    right: -35px;
}
.slick-prev:before, .slick-next:before {
    color: #044434;
    font-size: 30px;
}
.slick-dots li {
    margin: 0 2px;
}
.slick-dots li button, .slick-dots li button:before {
    width: 25px;
    height: 35px;
}
.slick-dots li button:before {
    line-height: 35px;
    font-size: 10px;
}
.slick-dots li.slick-active button:before {
    color: #044434;
}
@media screen and (max-width: 1023px) {
    .slick-prev{
        left: -30px;
    }
    .slick-next{
        right: -30px;
    }
}