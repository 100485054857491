.slider-item {
    height: 600px;
}

.slider-item > div > :is(p, ul) {
    transition: opacity .7s 0s;
    opacity: 0;
}

.slider-item.active > div > :is(p, ul) {
    opacity: 1;
    transition: opacity .5s .3s;
}

.slider-item > div > .anual-plan {
    opacity: 1;
    transition: opacity .5s .3s;
}

.slider-item.active > div > .anual-plan {
    transition: opacity .7s 0s;
    opacity: 0;
}

.slider-item.active {
    animation: 1s rotate;
}

.slider-item > div {
    transition: background-color .7s;
}

.slider-item.active > div {
    transition: background-color .5s .3s;
    background-color: rgba(0, 0, 0, 0.7);
}

@keyframes rotate {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}