.gg-play-button-o {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 44px;
    height: 44px;
    border: 2px solid;
    border-radius: 40px
}
.gg-play-button-o::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 20px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid;
    top: 10px;
    left: 16px
}